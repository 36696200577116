import { getEvent } from "@/services/event/get-event";
import { getEventSequence } from "@/services/event-sequence/get-event-sequence";
import createAsyncProcess from "@/utils/create-async-process";
import { fail, success } from "@/utils/either";
export const getEventWithSequence = createAsyncProcess(getEventWithSequenceImplementation);
async function getEventWithSequenceImplementation(eventId) {
    let event = null;
    const result = await getEvent.run(eventId);
    if (result.isOk()) {
        event = result.value;
        event.sequence = await getEventSequence.run(eventId);
        return success(event);
    }
    else {
        return fail(null);
    }
}
