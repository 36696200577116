import { defineComponent, computed, ref, watch, onBeforeUnmount } from "@vue/composition-api";
import EditableEvent from "@events/components/EditableEvent/index.vue";
import { getEventWithSequence } from "@/services/event/get-event-with-sequence";
import useRoute from "@/composable/use-route";
import { useEmitter } from "@/composable/emit";
export default defineComponent({
    name: "ViewEvent",
    inheritAttrs: false,
    components: {
        EditableEvent,
    },
    emits: {
        "event-downloaded": (event) => !!event,
        "event-not-found": (event) => event === null,
        "event-destroyed": (event) => event === null,
    },
    setup(props, { emit: emitter }) {
        const emit = useEmitter(emitter);
        const route = useRoute();
        const event = ref(null);
        const eventId = computed(() => Number(route.value.params.id));
        const eventNotFound = ref(false);
        const downloadEvent = async () => {
            emit("event-destroyed", null);
            eventNotFound.value = false;
            const result = await getEventWithSequence.run(eventId.value);
            if (result.isOk()) {
                event.value = result.value;
                emit("event-downloaded", result.value);
            }
            else {
                eventNotFound.value = true;
                emit("event-not-found", null);
            }
        };
        watch(eventId, downloadEvent, { immediate: true });
        onBeforeUnmount(() => emit("event-destroyed", null));
        return {
            eventDownloading: getEventWithSequence.active,
            event: computed(() => event.value),
            eventNotFound,
        };
    },
});
